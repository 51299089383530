/*
=======================================================
MAIN
=======================================================
*/
/*
=======================================================
Module: Config
=======================================================
*/
/*
=======================================================
Config
=======================================================
1. Grid Options
2. Set Assets Path
3. Viewports
4. Colors
5. Font Sizes
6. Form Elements
=======================================================
*/
/*
-------------------------------------------------------
1. Grid Options
-------------------------------------------------------
*/
/*
-------------------------------------------------------
2. Set Assets Path
-------------------------------------------------------
*/
/*
-------------------------------------------------------
3. Viewports
-------------------------------------------------------
*/
/*
-------------------------------------------------------
4. Colors
-------------------------------------------------------
*/
/*
-------------------------------------------------------
5. Font Sizes
-------------------------------------------------------
*/
/*
-------------------------------------------------------
6. Form Elements
-------------------------------------------------------
*/
/*
=======================================================
Module: Utils
=======================================================
*/
/*
=======================================================
Functions
=======================================================
1. Get breakpoints
2. Get colors
3. Get font sizes
4. Generate grid column
5. Get assets URL
6. String helpers
=======================================================
*/
/*
-------------------------------------------------------
1. Get breakpoints
-------------------------------------------------------
*/
/*
-------------------------------------------------------
2. Get colors
-------------------------------------------------------
*/
/*
-------------------------------------------------------
3. Get font sizes
-------------------------------------------------------
*/
/*
-------------------------------------------------------
4. Generate grid column
-------------------------------------------------------
*/
/*
-------------------------------------------------------
5. Get assets URL
-------------------------------------------------------
*/
/*
-------------------------------------------------------
6. String helpers
-------------------------------------------------------
*/
/*
=======================================================
Mixins
=======================================================
1. Breakpoints
2. Container
3. Grid
4. Font-Face
5. Spacing
6. Offset Positioning
7. Utilities
=======================================================
*/
/*
-------------------------------------------------------
1. Breakpoints
-------------------------------------------------------
*/
/*
-------------------------------------------------------
2. Container
-------------------------------------------------------
*/
/*
-------------------------------------------------------
3. Grid
-------------------------------------------------------
*/
/*
-------------------------------------------------------
4. Font-Face
-------------------------------------------------------
*/
/*
-------------------------------------------------------
5. Spacing
-------------------------------------------------------
*/
/*
-------------------------------------------------------
6. Offset Positioning
-------------------------------------------------------
*/
/*
-------------------------------------------------------
7. Utilities
-------------------------------------------------------
*/
/*
=======================================================
Helpers
=======================================================
1. Background
2. Container
3. Display
4. Floating
5. Hide/Show Elements
6. Misc
7. Padding
8. Position
9. Sizing
10. Spacers
11. Text Utilities
12. Z-Index
13. Animations
=======================================================
*/
/*
-------------------------------------------------------
1. Background
-------------------------------------------------------
*/
.u-bgLight {
  background-color: #fff; }

.u-bgDark {
  background-color: #222; }

.u-bgGray {
  background-color: #cecece; }

.u-bgGrayDark {
  background-color: #b5b5b5; }

.u-bgGrayLight {
  background-color: #e8e8e8; }

.u-bgMain {
  background-color: #24292e; }

.u-bgMainDark {
  background-color: #0e1011; }

.u-bgMainLight {
  background-color: #3a434b; }

.u-bgImage {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover; }

/*
-------------------------------------------------------
2. Container
-------------------------------------------------------
*/
.container {
  max-width: 1280px;
  margin: 0 auto; }

/*
-------------------------------------------------------
3. Display
-------------------------------------------------------
*/
.u-block {
  display: block; }

.u-inline {
  display: inline; }

.u-inlineBlock {
  display: inline-block; }

.u-table {
  display: table; }

.u-tableCell {
  display: table-cell; }

.u-flex {
  display: flex; }

/*
-------------------------------------------------------
4. Floating
-------------------------------------------------------
*/
.u-fleft {
  float: left; }

.u-fright {
  float: right; }

.u-fnone {
  float: none; }

.u-clear {
  clear: both; }

/*
-------------------------------------------------------
5. Hide/Show Elements
-------------------------------------------------------
*/
.u-hide,
.u-xs-hide,
.u-sm-hide,
.u-md-hide,
.u-lg-hide,
.u-xl-hide {
  visibility: hidden;
  position: absolute;
  top: -9999px;
  left: -9999px; }

@media (min-width: 481px) {
  .u-xs-hide {
    visibility: inherit;
    position: static;
    top: auto;
    left: auto; }
  .u-xs-show {
    visibility: hidden;
    position: absolute;
    top: -9999px;
    left: -9999px; } }

@media (min-width: 601px) {
  .u-sm-hide {
    visibility: inherit;
    position: static;
    top: auto;
    left: auto; }
  .u-sm-show {
    visibility: hidden;
    position: absolute;
    top: -9999px;
    left: -9999px; } }

@media (min-width: 841px) {
  .u-md-hide {
    visibility: inherit;
    position: static;
    top: auto;
    left: auto; }
  .u-md-show {
    visibility: hidden;
    position: absolute;
    top: -9999px;
    left: -9999px; } }

@media (min-width: 961px) {
  .u-lg-hide {
    visibility: inherit;
    position: static;
    top: auto;
    left: auto; }
  .u-lg-show {
    visibility: hidden;
    position: absolute;
    top: -9999px;
    left: -9999px; } }

@media (min-width: 1281px) {
  .u-xl-hide {
    visibility: inherit;
    position: static;
    top: auto;
    left: auto; }
  .u-xl-show {
    visibility: hidden;
    position: absolute;
    top: -9999px;
    left: -9999px; } }

/*
-------------------------------------------------------
6. Misc
-------------------------------------------------------
*/
.u-tag,
.u-vcenter {
  display: inline-block;
  vertical-align: middle; }

.u-tag {
  padding: 0.4em 0.6em;
  border-radius: 0.3rem; }

.u-hideOverflow {
  overflow: hidden; }

.u-smoothTransition {
  transition: all 0.3s; }

.u-noUnderline:hover {
  text-decoration: none; }

/*
-------------------------------------------------------
7. Padding
-------------------------------------------------------
*/
.u-pad-05 {
  padding: 0.5em; }

.u-pad-10 {
  padding: 1em; }

.u-pad-15 {
  padding: 1.5em; }

.u-pad-20 {
  padding: 2em; }

.u-pad-25 {
  padding: 2.5em; }

.u-pad-30 {
  padding: 3em; }

.u-padTop-05 {
  padding-top: 0.5em; }

.u-padTop-10 {
  padding-top: 1em; }

.u-padTop-15 {
  padding-top: 1.5em; }

.u-padTop-20 {
  padding-top: 2em; }

.u-padTop-25 {
  padding-top: 2.5em; }

.u-padTop-30 {
  padding-top: 3em; }

.u-padBottom-05 {
  padding-bottom: 0.5em; }

.u-padBottom-10 {
  padding-bottom: 1em; }

.u-padBottom-15 {
  padding-bottom: 1.5em; }

.u-padBottom-20 {
  padding-bottom: 2em; }

.u-padBottom-25 {
  padding-bottom: 2.5em; }

.u-padBottom-30 {
  padding-bottom: 3em; }

/*
-------------------------------------------------------
8. Position
-------------------------------------------------------
*/
.u-relative {
  position: relative; }

.u-absolute {
  position: absolute; }

.u-fixed {
  position: fixed; }

/*
-------------------------------------------------------
9. Sizing
-------------------------------------------------------
*/
.u-autoSize,
.u-autoWidth {
  width: auto; }

.u-autoSize,
.u-autoHeight {
  height: auto; }

.u-fullSize,
.u-fullWidth {
  width: 100%; }

.u-fullSize,
.u-fullHeight {
  height: 100%; }

/*
-------------------------------------------------------
10. Spacers
-------------------------------------------------------
*/
.u-spacer-05 {
  margin: 0.5em 0; }

.u-spacer-10 {
  margin: 1em 0; }

.u-spacer-15 {
  margin: 1.5em 0; }

.u-spacer-20 {
  margin: 2em 0; }

.u-spacer-25 {
  margin: 2.5em 0; }

.u-spacer-30 {
  margin: 3em 0; }

.u-spacerTop-05 {
  margin-top: 0.5em; }

.u-spacerTop-10 {
  margin-top: 1em; }

.u-spacerTop-15 {
  margin-top: 1.5em; }

.u-spacerTop-20 {
  margin-top: 2em; }

.u-spacerTop-25 {
  margin-top: 2.5em; }

.u-spacerTop-30 {
  margin-top: 3em; }

.u-spacerBottom-05 {
  margin-bottom: 0.5em; }

.u-spacerBottom-10 {
  margin-bottom: 1em; }

.u-spacerBottom-15 {
  margin-bottom: 1.5em; }

.u-spacerBottom-20 {
  margin-bottom: 2em; }

.u-spacerBottom-25 {
  margin-bottom: 2.5em; }

.u-spacerBottom-30 {
  margin-bottom: 3em; }

/*
-------------------------------------------------------
11. Text Utilities
-------------------------------------------------------
*/
.u-colorLight {
  color: #fff; }

.u-colorDark {
  color: #222; }

.u-colorGray {
  color: #cecece; }

.u-colorGrayDark {
  color: #b5b5b5; }

.u-colorGrayLight {
  color: #e8e8e8; }

.u-colorMain {
  color: #24292e; }

.u-colorMainDark {
  color: #0e1011; }

.u-colorMainLight {
  color: #3a434b; }

.u-fontSizeSmall {
  font-size: 0.75em; }

.u-fontSizeDefault {
  font-size: 1em; }

h1,
.u-fontSizeTitle-1 {
  font-size: 2.5em; }

h2,
.u-fontSizeTitle-2 {
  font-size: 2.25em; }

h3,
.u-fontSizeTitle-3 {
  font-size: 2em; }

h4,
.u-fontSizeTitle-4 {
  font-size: 1.75em; }

h5,
.u-fontSizeTitle-5 {
  font-size: 1.5em; }

h6,
.u-fontSizeTitle-6 {
  font-size: 1.25em; }

.u-fontSizeDisplay-1 {
  font-size: 3.5em; }

.u-fontSizeDisplay-2 {
  font-size: 3.25em; }

.u-fontSizeDisplay-3 {
  font-size: 3em; }

.u-fontSizeDisplay-4 {
  font-size: 2.75em; }

.u-textCenter {
  text-align: center; }

.u-textLeft {
  text-align: left; }

.u-textRight {
  text-align: right; }

.u-textUppercase {
  text-transform: uppercase; }

.u-textBlock {
  line-height: 200%; }

/*
-------------------------------------------------------
12. Z-Index
-------------------------------------------------------
*/
.u-zIndex-1 {
  z-index: 1; }

.u-zIndex-2 {
  z-index: 2; }

.u-zIndex-3 {
  z-index: 3; }

.u-zIndex-4 {
  z-index: 4; }

.u-zIndex-5 {
  z-index: 5; }

.u-zIndex-6 {
  z-index: 6; }

/*
-------------------------------------------------------
13. Animations
-------------------------------------------------------
*/
@keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes fadeOut {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

/*
=======================================================
Module: Base
=======================================================
*/
/*
=======================================================
CSS Reset
=======================================================
1. Defaults
2. Form Elements
3. Misc
=======================================================
*/
/*
-------------------------------------------------------
1. Defaults
-------------------------------------------------------
*/
*,
*::after,
*::before {
  box-sizing: border-box;
  padding: 0;
  margin: 0; }

@-ms-viewport {
  width: device-width; }

html {
  background-color: #fff;
  font-size: 1em;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

a,
label,
input, button, select, textarea {
  touch-action: manipulation; }

label,
button,
select,
input[type="submit"] {
  cursor: pointer; }

iframe,
fieldset {
  border: 0; }

small {
  font-size: 0.75em; }

html,
body {
  overflow-x: hidden; }

/*
-------------------------------------------------------
2. Form Elements
-------------------------------------------------------
*/
input, button, select, textarea {
  color: #222;
  font-family: inherit;
  font-size: 1em; }

button {
  border: none;
  background-color: transparent; }
  button:focus {
    outline: none; }

select::-ms-expand {
  display: none; }

select::-ms-value {
  background: none;
  color: inherit; }

select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #222; }

textarea {
  resize: none;
  overflow-y: auto; }

/*
-------------------------------------------------------
3. Misc
-------------------------------------------------------
*/
a {
  color: inherit;
  text-decoration: none; }
  a:hover {
    text-decoration: underline; }

img {
  max-width: 100%; }

hr {
  border: 0;
  height: 1px;
  background-color: #cecece; }

ul,
ol {
  list-style: none; }

[hidden] {
  display: none; }

/*
=======================================================
Grid
=======================================================
1. Row
2. Columns
3. Responsive Columns
=======================================================
*/
/*
-------------------------------------------------------
1. Row
-------------------------------------------------------
*/
.row {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start; }
  .row:not(.no-gutter),
  .row:not(.no-gutter) > [class^='col'] {
    padding: 0.7em; }
  .row .row {
    margin: -0.7em;
    padding: 0; }
  .row-centered {
    justify-content: center; }
  .row-inverted {
    justify-content: flex-end; }

/*
-------------------------------------------------------
2. Columns
-------------------------------------------------------
*/
.col {
  flex: 1 1 0; }
  .col-vcenter {
    align-self: center; }
  .col-vbottom {
    align-self: flex-end; }
  .col-stretch {
    align-self: stretch; }
  .col-mx-auto {
    margin: 0 auto; }
  .col-ml-auto {
    margin-left: auto; }
  .col-mr-auto {
    margin-right: auto; }

/*
-------------------------------------------------------
3. Responsive Columns
-------------------------------------------------------
*/
@media (min-width: 0) {
  .col-xs-1 {
    width: 8.33333%; }
  .col-xs-2 {
    width: 16.66667%; }
  .col-xs-3 {
    width: 25%; }
  .col-xs-4 {
    width: 33.33333%; }
  .col-xs-5 {
    width: 41.66667%; }
  .col-xs-6 {
    width: 50%; }
  .col-xs-7 {
    width: 58.33333%; }
  .col-xs-8 {
    width: 66.66667%; }
  .col-xs-9 {
    width: 75%; }
  .col-xs-10 {
    width: 83.33333%; }
  .col-xs-11 {
    width: 91.66667%; }
  .col-xs-12 {
    width: 100%; } }

@media (min-width: 601px) {
  .col-sm-1 {
    width: 8.33333%; }
  .col-sm-2 {
    width: 16.66667%; }
  .col-sm-3 {
    width: 25%; }
  .col-sm-4 {
    width: 33.33333%; }
  .col-sm-5 {
    width: 41.66667%; }
  .col-sm-6 {
    width: 50%; }
  .col-sm-7 {
    width: 58.33333%; }
  .col-sm-8 {
    width: 66.66667%; }
  .col-sm-9 {
    width: 75%; }
  .col-sm-10 {
    width: 83.33333%; }
  .col-sm-11 {
    width: 91.66667%; }
  .col-sm-12 {
    width: 100%; } }

@media (min-width: 841px) {
  .col-md-1 {
    width: 8.33333%; }
  .col-md-2 {
    width: 16.66667%; }
  .col-md-3 {
    width: 25%; }
  .col-md-4 {
    width: 33.33333%; }
  .col-md-5 {
    width: 41.66667%; }
  .col-md-6 {
    width: 50%; }
  .col-md-7 {
    width: 58.33333%; }
  .col-md-8 {
    width: 66.66667%; }
  .col-md-9 {
    width: 75%; }
  .col-md-10 {
    width: 83.33333%; }
  .col-md-11 {
    width: 91.66667%; }
  .col-md-12 {
    width: 100%; } }

@media (min-width: 961px) {
  .col-lg-1 {
    width: 8.33333%; }
  .col-lg-2 {
    width: 16.66667%; }
  .col-lg-3 {
    width: 25%; }
  .col-lg-4 {
    width: 33.33333%; }
  .col-lg-5 {
    width: 41.66667%; }
  .col-lg-6 {
    width: 50%; }
  .col-lg-7 {
    width: 58.33333%; }
  .col-lg-8 {
    width: 66.66667%; }
  .col-lg-9 {
    width: 75%; }
  .col-lg-10 {
    width: 83.33333%; }
  .col-lg-11 {
    width: 91.66667%; }
  .col-lg-12 {
    width: 100%; } }

@media (min-width: 1281px) {
  .col-xl-1 {
    width: 8.33333%; }
  .col-xl-2 {
    width: 16.66667%; }
  .col-xl-3 {
    width: 25%; }
  .col-xl-4 {
    width: 33.33333%; }
  .col-xl-5 {
    width: 41.66667%; }
  .col-xl-6 {
    width: 50%; }
  .col-xl-7 {
    width: 58.33333%; }
  .col-xl-8 {
    width: 66.66667%; }
  .col-xl-9 {
    width: 75%; }
  .col-xl-10 {
    width: 83.33333%; }
  .col-xl-11 {
    width: 91.66667%; }
  .col-xl-12 {
    width: 100%; } }

/*
=======================================================
Typography
=======================================================
1. Font-Face
2. Defaults
=======================================================
*/
/*
-------------------------------------------------------
1. Font-Face
-------------------------------------------------------
*/
@font-face {
  font-family: "Neo Sans Pro";
  src: url("../../dist/fonts/NeoSansPro/NeoSansPro-Regular.eot");
  src: url("../../dist/fonts/NeoSansPro/NeoSansPro-Regular.eot") format("embedded-opentype"), url("../../dist/fonts/NeoSansPro/NeoSansPro-Regular.woff2") format("woff2"), url("../../dist/fonts/NeoSansPro/NeoSansPro-Regular.woff") format("woff"), url("../../dist/fonts/NeoSansPro/NeoSansPro-Regular.ttf") format("truetype"), url("../../dist/fonts/NeoSansPro/NeoSansPro-Regular.svg#NeoSansPro-Regular") format("svg");
  font-weight: normal; }

.u-fontNeoSansProRegular {
  font-family: "Neo Sans Pro";
  font-weight: normal; }

@font-face {
  font-family: "Neo Sans Pro";
  src: url("../../dist/fonts/NeoSansPro/NeoSansPro-Bold.eot");
  src: url("../../dist/fonts/NeoSansPro/NeoSansPro-Bold.eot") format("embedded-opentype"), url("../../dist/fonts/NeoSansPro/NeoSansPro-Bold.woff2") format("woff2"), url("../../dist/fonts/NeoSansPro/NeoSansPro-Bold.woff") format("woff"), url("../../dist/fonts/NeoSansPro/NeoSansPro-Bold.ttf") format("truetype"), url("../../dist/fonts/NeoSansPro/NeoSansPro-Bold.svg#NeoSansPro-Bold") format("svg");
  font-weight: bold; }

.u-fontNeoSansProBold {
  font-family: "Neo Sans Pro";
  font-weight: bold; }

/*
-------------------------------------------------------
2. Defaults
-------------------------------------------------------
*/
body {
  color: #222;
  font-family: 'Neo Sans Pro', sans-serif;
  font-weight: normal; }
  @media (max-width: 840px) {
    body {
      font-size: 85%; } }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 700; }

/*
=======================================================
Module: Components
=======================================================
*/
/*
=======================================================
Buttons
=======================================================
1. Default Styles
=======================================================
*/
/*
-------------------------------------------------------
1. Default Styles
-------------------------------------------------------
*/
.btn {
  font-weight: 700;
  letter-spacing: 0.15rem;
  font-size: 1.25em;
  color: #fff;
  padding: 0.3em 2em;
  text-align: center;
  line-height: 1.5em;
  border-width: 2px;
  border-style: solid;
  text-transform: uppercase;
  border-radius: 3rem;
  transition: background 0.3s, border 0.3s, color 0.3s;
  background-color: #b8ad8d;
  border-color: #b8ad8d;
  display: inline-block;
  vertical-align: middle; }
  .btn:focus, .btn:hover {
    outline: none;
    text-decoration: none;
    background-color: #a4966e;
    border-color: #a4966e; }
  .btn span {
    display: inline-block;
    vertical-align: middle; }
  .btn-icon {
    fill: #222;
    display: inline-block;
    vertical-align: middle;
    width: 1em;
    height: 1em;
    transition: fill 0.3s; }
    span + .btn-icon,
    .btn-icon + span {
      margin-left: 0.7em; }
  .btn--outline:not(:hover):not(:focus) {
    color: inherit;
    background-color: transparent; }
    .btn--outline:not(:hover):not(:focus) .btn-icon {
      fill: #222; }
  .btn:disabled, .btn.is-disabled {
    z-index: -1;
    opacity: 0.6;
    position: relative;
    pointer-events: none; }

/*
=======================================================
Hero Image
=======================================================
1. Height
=======================================================
*/
/*
-------------------------------------------------------
1. Height
-------------------------------------------------------
*/
.heroImage .container > .row {
  height: 50vh; }
  @media (min-width: 841px) {
    .heroImage .container > .row {
      height: 100vh; } }

/*
=======================================================
Module: Layout
=======================================================
*/
/*
=======================================================
Header
=======================================================
1. Base Styles
=======================================================
*/
/*
-------------------------------------------------------
1. Base Styles
-------------------------------------------------------
*/
/*
=======================================================
Sections
=======================================================
*/
.section-1 {
  z-index: 6;
  padding: 2em 0;
  position: relative; }
  .section-1 h2 {
    color: #376e82; }
  .section-1::before {
    height: 3em;
    content: '';
    position: absolute;
    top: -3em;
    right: -2em;
    left: -2em;
    background: url("../../dist/img/bg-section-1-up.png") center no-repeat;
    background-size: 100% 100%; }
  @media (min-width: 841px) {
    .section-1::before {
      left: 0;
      right: 0; }
    .section-1 .icon {
      z-index: 1;
      width: 6em;
      height: 6em;
      position: absolute;
      bottom: -6em;
      left: 14em; } }

.section-2 {
  z-index: 5;
  position: relative;
  padding: 4em 0;
  background-color: #58b7cd; }
  .section-2 ul {
    columns: 2; }
  .section-2 .icon {
    z-index: 1;
    width: 6em;
    height: 6em;
    position: absolute;
    right: 38em;
    bottom: -6em; }
  .section-2::after {
    height: 8em;
    content: '';
    position: absolute;
    right: -2em;
    bottom: -5em;
    left: -2em;
    background: url("../../dist/img/bg-section-2-down.png") right no-repeat;
    background-size: contain; }
  @media (min-width: 841px) {
    .section-2 {
      padding: 12em 0 6em 0; }
      .section-2::after {
        left: 0;
        right: 10vw;
        bottom: -8em; } }

.section-3 {
  z-index: 4;
  padding: 8em 0 0;
  background-color: #f1f1f1; }
  @media (max-width: 840px) {
    .section-3 {
      text-align: center; } }
  .section-3 .pessoa-fisica {
    padding: 2em 0 8em;
    background-color: #e4eaec; }
  .section-3 .pessoa-juridica {
    padding-bottom: 2em; }
  .section-3 h2,
  .section-3 h3 {
    color: #376e82; }
  .section-3 h3 + img {
    max-width: 16em; }
  .section-3 .logo {
    max-width: 11em; }
  .section-3 .ans {
    max-width: 7em; }
  .section-3 ul {
    color: #5c5c5c; }
  .section-3 li {
    margin-top: 2em; }

.section-4 {
  z-index: 3;
  position: relative;
  background-color: #00c4ed; }
  .section-4 img {
    margin-top: -4em; }
  .section-4::before {
    z-index: -1;
    content: '';
    height: 6em;
    position: absolute;
    top: -3.5em;
    right: 3em;
    left: 3em;
    background: url("../../dist/img/bg-section-4-up.png") center no-repeat;
    background-size: contain; }
    @media (min-width: 841px) {
      .section-4::before {
        width: 40vw;
        height: 4em;
        transform: translateX(-50%);
        position: absolute;
        top: -3em;
        left: 50%; } }

.section-5 {
  z-index: 2;
  padding: 4em 0;
  background-color: #c8ecea; }
  .section-5 h2 + img {
    height: 2em;
    margin: 0 0 -0.5em 0.5em; }

.section-6 {
  z-index: 1;
  position: relative;
  background-color: #6c9eac; }
  .section-6::before {
    height: 4em;
    content: '';
    position: absolute;
    top: -3em;
    right: -2em;
    left: -2em;
    background: url("../../dist/img/bg-section-6-up.png") center no-repeat;
    background-size: 100% 100%; }
    @media (min-width: 841px) {
      .section-6::before {
        width: 40vw;
        height: 4em;
        transform: translateX(-50%);
        position: absolute;
        top: -3em;
        left: 50%; } }
  .section-6 .btn {
    margin-bottom: -3em; }

/*
=======================================================
Footer
=======================================================
1. Base Styles
=======================================================
*/
/*
-------------------------------------------------------
1. Base Styles
-------------------------------------------------------
*/
.mainFooter {
  padding-top: 4em; }
  .mainFooter .u-flex {
    align-items: center;
    justify-content: center; }
  @media (max-width: 840px) {
    .mainFooter .col-xs-12 > .u-flex {
      display: block;
      text-align: center; } }
  .mainFooter .flex-image--logo img {
    max-width: 7em; }
  @media (max-width: 840px) {
    .mainFooter .flex-image--logo {
      margin-bottom: 2em; } }
  @media (min-width: 841px) {
    .mainFooter .flex-image--logo {
      margin-right: 2em; } }
  .mainFooter .flex-image--icon {
    margin-right: 1em; }

/*
=======================================================
Module: Vendor
=======================================================
*/
