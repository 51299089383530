/*
=======================================================
Functions
=======================================================
1. Get breakpoints
2. Get colors
3. Get font sizes
4. Generate grid column
5. Get assets URL
6. String helpers
=======================================================
*/

/*
-------------------------------------------------------
1. Get breakpoints
-------------------------------------------------------
*/

@function breakpoint($key) {
  @return map-get($breakpoints, $key);
}

/*
-------------------------------------------------------
2. Get colors
-------------------------------------------------------
*/

@function color($color) {
  @if map-has-key($colors, $color) {
    @return map-get($colors, $color);
  }

  @error 'Invalid color name: #{$color}.';
}

/*
-------------------------------------------------------
3. Get font sizes
-------------------------------------------------------
*/

@function font-size($key) {
  @if map-has-key($fontSizes, $key) {
    @return map-get($fontSizes, $key);
  }

  @error 'Invalid font-size: #{$key}.';
}

/*
-------------------------------------------------------
4. Generate grid column
-------------------------------------------------------
*/

@function column($columnSize, $gridSize) {
  @return $columnSize / $gridSize * 100%;
}

/*
-------------------------------------------------------
5. Get assets URL
-------------------------------------------------------
*/

@function assets-url($path, $file) {
  @return url($assetsPath + '/' + $path + '/' + $file);
}

@function fonts($file) {
  @return assets-url('fonts', $file);
}

@function img($file) {
  @return assets-url('img', $file);
}

@function svg($file) {
  @return assets-url('svg', $file);
}

/*
-------------------------------------------------------
6. String helpers
-------------------------------------------------------
*/

@function contain($list, $value) {
  @return not not index($list, $value);
}

@function capitalize($string) {
  @return to-upper-case(str-slice($string, 1, 1)) + str-slice($string, 2);
}

@function str-ucwords($string) {
  $progress: $string;
  $result: '';

  $running: true;

  @while $running {
    $index: str-index($progress, ' ');
    @if $index {
      $result: $result + capitalize(str-slice($progress, 1, $index));
      $progress: str-slice($progress, ($index + 1));
    }
    @else {
      $running: false;
    }
  }

  @return capitalize($result) + capitalize($progress);
}

@function camelize($string) {
  $progress: $string;
  $result: '';
  $exclude: ' ', '-', '–', '—', '_', ',', ';', ':', '.';

  @while str-length($progress) > 0 {
    $char: str-slice($progress, 1, 1);

    @if contain($exclude, $char) {
      $progress: capitalize(str-slice($progress, 2, 2)) + str-slice($progress, 3);
    }

    @else {
      $result: $result + $char;
      $progress: str-slice($progress, 2);
    }
  }

  @return $result;
}
