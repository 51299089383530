/*
=======================================================
Hero Image
=======================================================
1. Height
=======================================================
*/

/*
-------------------------------------------------------
1. Height
-------------------------------------------------------
*/

.heroImage {
  .container > .row {
    height: 50vh;

    @include media-breakpoint(md) {
      height: 100vh;
    }
  }
}
