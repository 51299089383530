/*
=======================================================
Config
=======================================================
1. Grid Options
2. Set Assets Path
3. Viewports
4. Colors
5. Font Sizes
6. Form Elements
=======================================================
*/

/*
-------------------------------------------------------
1. Grid Options
-------------------------------------------------------
*/

$grid-cols: 12;
$grid-gutter: 0.7em;

/*
-------------------------------------------------------
2. Set Assets Path
-------------------------------------------------------
*/

$assetsPath: '../../dist';

/*
-------------------------------------------------------
3. Viewports
-------------------------------------------------------
*/

$breakpoints: (
  xs: 480px,
  sm: 600px,
  md: 840px,
  lg: 960px,
  xl: 1280px
);

/*
-------------------------------------------------------
4. Colors
-------------------------------------------------------
*/

$colorBase-main: #24292e;
$colorBase-gray: #cecece;
$colorVariation-ratio: 10%;

$colors: (
  light:      #fff,
  dark:       #222,
  gray:       $colorBase-gray,
  grayDark:   darken($colorBase-gray, $colorVariation-ratio),
  grayLight:  lighten($colorBase-gray, $colorVariation-ratio),
  main:       $colorBase-main,
  mainDark:   darken($colorBase-main, $colorVariation-ratio),
  mainLight:  lighten($colorBase-main, $colorVariation-ratio)
);

/*
-------------------------------------------------------
5. Font Sizes
-------------------------------------------------------
*/

$fontSize-default:  1em;
$fontSize-ratio:    0.25;

$fontSizes: (
  small:     $fontSize-default - $fontSize-ratio,
  default:   $fontSize-default,
  title-6:   $fontSize-default + $fontSize-ratio * 1,
  title-5:   $fontSize-default + $fontSize-ratio * 2,
  title-4:   $fontSize-default + $fontSize-ratio * 3,
  title-3:   $fontSize-default + $fontSize-ratio * 4,
  title-2:   $fontSize-default + $fontSize-ratio * 5,
  title-1:   $fontSize-default + $fontSize-ratio * 6,
  display-4: $fontSize-default + $fontSize-ratio * 7,
  display-3: $fontSize-default + $fontSize-ratio * 8,
  display-2: $fontSize-default + $fontSize-ratio * 9,
  display-1: $fontSize-default + $fontSize-ratio * 10
);

/*
-------------------------------------------------------
6. Form Elements
-------------------------------------------------------
*/

$formElements: 'input, button, select, textarea';
$formElements-borderRadius: 0.3rem;
