/*
=======================================================
CSS Reset
=======================================================
1. Defaults
2. Form Elements
3. Misc
=======================================================
*/

/*
-------------------------------------------------------
1. Defaults
-------------------------------------------------------
*/

*,
*::after,
*::before {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

@-ms-viewport {
  width: device-width;
}

html {
  background-color: color(light);
  font-size: font-size('default');
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

a,
label,
#{$formElements} {
  touch-action: manipulation;
}

label,
button,
select,
input[type="submit"] {
  cursor: pointer;
}

iframe,
fieldset {
  border: 0;
}

small {
  font-size: font-size('small');
}

html,
body {
  overflow-x: hidden;
}

/*
-------------------------------------------------------
2. Form Elements
-------------------------------------------------------
*/

#{$formElements} {
  color: color(dark);
  font-family: inherit;
  font-size: font-size('default');
}

button {
  border: none;
  background-color: transparent;

  &:focus {
    outline: none;
  }
}

select {
  &::-ms-expand {
    display: none;
  }

  &::-ms-value {
    background: none;
    color: inherit;
  }

  &:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 color(dark);
  }
}

textarea {
  resize: none;
  overflow-y: auto;
}

/*
-------------------------------------------------------
3. Misc
-------------------------------------------------------
*/

a {
  color: inherit;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

img {
  max-width: 100%;
}

hr {
  border: 0;
  height: 1px;
  background-color: color(gray);
}

ul,
ol {
  list-style: none;
}

[hidden] {
  display: none;
}
