/*
=======================================================
Footer
=======================================================
1. Base Styles
=======================================================
*/

/*
-------------------------------------------------------
1. Base Styles
-------------------------------------------------------
*/

.mainFooter {
  padding-top: 4em;

  .u-flex {
    align-items: center;
    justify-content: center;
  }

  .col-xs-12 > .u-flex {
    @include media-breakpoint(md, max) {
      display: block;
      text-align: center;
    }
  }

  .flex-image {
    &--logo {
      img {
        max-width: 7em;
      }

      @include media-breakpoint(md, max) {
        margin-bottom: 2em;
      }

      @include media-breakpoint(md) {
        margin-right: 2em;
      }
    }

    &--icon {
      margin-right: 1em;
    }
  }
}
