/*
=======================================================
Grid
=======================================================
1. Row
2. Columns
3. Responsive Columns
=======================================================
*/

/*
-------------------------------------------------------
1. Row
-------------------------------------------------------
*/

.row {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;

  &:not(.no-gutter),
  &:not(.no-gutter) > [class^='col'] {
    padding: $grid-gutter;
  }

  & & {
    margin: -$grid-gutter;
    padding: 0;
  }

  &-centered {
    justify-content: center;
  }

  &-inverted {
    justify-content: flex-end;
  }
}

/*
-------------------------------------------------------
2. Columns
-------------------------------------------------------
*/

.col {
  flex: 1 1 0;

  &-vcenter {
    align-self: center;
  }

  &-vbottom {
    align-self: flex-end;
  }

  &-stretch {
    align-self: stretch;
  }

  &-mx-auto {
    margin: 0 auto;
  }

  &-ml-auto {
    margin-left: auto;
  }

  &-mr-auto {
    margin-right: auto;
  }
}

/*
-------------------------------------------------------
3. Responsive Columns
-------------------------------------------------------
*/

@each $breakpoint in $breakpoints {
  $breakpoint: nth($breakpoint, 1);

  @include media-breakpoint(if($breakpoint == xs, -1, $breakpoint)) {
    @include grid('col-' + $breakpoint);
  }
}
