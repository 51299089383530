/*
=======================================================
Helpers
=======================================================
1. Background
2. Container
3. Display
4. Floating
5. Hide/Show Elements
6. Misc
7. Padding
8. Position
9. Sizing
10. Spacers
11. Text Utilities
12. Z-Index
13. Animations
=======================================================
*/

/*
-------------------------------------------------------
1. Background
-------------------------------------------------------
*/

@each $class, $color in $colors {
  $class: '.u-bg' + str-ucwords(#{$class});

  #{$class} {
    background-color: $color;
  }
}

.u-bgImage {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

/*
-------------------------------------------------------
2. Container
-------------------------------------------------------
*/

.container {
  @include container(1280px);
}

/*
-------------------------------------------------------
3. Display
-------------------------------------------------------
*/

@each $property in 'block', 'inline', 'inline-block', 'table', 'table-cell', 'flex' {
  .u-#{camelize($property)} {
    display: #{$property};
  }
}

/*
-------------------------------------------------------
4. Floating
-------------------------------------------------------
*/

@each $float in 'left', 'right', 'none' {
  .u-f#{$float} {
    float: #{$float};
  }
}

.u-clear {
  clear: both;
}

/*
-------------------------------------------------------
5. Hide/Show Elements
-------------------------------------------------------
*/

.u-hide,
.u-xs-hide,
.u-sm-hide,
.u-md-hide,
.u-lg-hide,
.u-xl-hide {
  @include hideElement;
}

@each $breakpoint in $breakpoints {
  $breakpoint: nth($breakpoint, 1);

  @include media-breakpoint($breakpoint) {
    .u-#{$breakpoint}-hide {
      @include showElement;
    }

    .u-#{$breakpoint}-show {
      @include hideElement;
    }
  }
}

/*
-------------------------------------------------------
6. Misc
-------------------------------------------------------
*/

.u-tag,
.u-vcenter {
  @include vcenter;
}

.u-tag {
  padding: 0.4em 0.6em;
  border-radius: $formElements-borderRadius;
}

.u-hideOverflow {
  overflow: hidden;
}

.u-smoothTransition {
  transition: all 0.3s;
}

.u-noUnderline:hover {
  text-decoration: none;
}

/*
-------------------------------------------------------
7. Padding
-------------------------------------------------------
*/

@include spacing('u-pad-', 'padding');
@include spacing('u-padTop-', 'padding-top');
@include spacing('u-padBottom-', 'padding-bottom');

/*
-------------------------------------------------------
8. Position
-------------------------------------------------------
*/

@each $position in 'relative', 'absolute', 'fixed' {
  .u-#{$position} {
    position: #{$position};
  }
}

/*
-------------------------------------------------------
9. Sizing
-------------------------------------------------------
*/

.u-autoSize,
.u-autoWidth {
  width: auto;
}

.u-autoSize,
.u-autoHeight {
  height: auto;
}

.u-fullSize,
.u-fullWidth {
  width: 100%;
}

.u-fullSize,
.u-fullHeight {
  height: 100%;
}

/*
-------------------------------------------------------
10. Spacers
-------------------------------------------------------
*/

@include spacing('u-spacer-', 'margin', true);
@include spacing('u-spacerTop-', 'margin-top');
@include spacing('u-spacerBottom-', 'margin-bottom');

/*
-------------------------------------------------------
11. Text Utilities
-------------------------------------------------------
*/

@each $class, $color in $colors {
  .u-#{camelize('color-' + $class)} {
    color: $color;
  }
}

@each $size in 'small', 'default' {
  .u-#{camelize('fontSize-' + $size)} {
    font-size: font-size($size);
  }
}

@for $i from 1 through 6 {
  h#{$i},
  .u-fontSizeTitle-#{$i} {
    font-size: font-size('title-#{$i}');
  }
}

@for $i from 1 through 4 {
  .u-fontSizeDisplay-#{$i} {
    font-size: font-size('display-#{$i}');
  }
}

@each $align in 'center', 'left', 'right' {
  .u-#{camelize('text-' + $align)} {
    text-align: #{$align};
  }
}

.u-textUppercase {
  text-transform: uppercase;
}

.u-textBlock {
  line-height: 200%;
}

/*
-------------------------------------------------------
12. Z-Index
-------------------------------------------------------
*/

@for $i from 1 through 6 {
  .u-zIndex-#{$i} {
    z-index: $i;
  }
}

/*
-------------------------------------------------------
13. Animations
-------------------------------------------------------
*/

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}
