/*
=======================================================
Sections
=======================================================
*/

.section-1 {
  z-index: 6;
  padding: 2em 0;
  position: relative;

  h2 {
    color: #376e82;
  }

  &::before {
    height: 3em;
    content: '';
    @include absolute($top: -3em, $left: -2em, $right: -2em);
    background: img('bg-section-1-up.png') center no-repeat;
    background-size: 100% 100%;
  }

  @include media-breakpoint(md) {
    &::before {
      left: 0;
      right: 0;
    }

    // &::after {
    //   height: 8em;
    //   content: '';
    //   @include absolute($bottom: -8em, $left: 0, $right: 0);
    //   background: img('bg-section-1-down.png') left no-repeat;
    //   background-size: contain;
    // }

    .icon {
      z-index: 1;
      @include size(6em);
      @include absolute($bottom: -6em, $left: 14em);
    }
  }
}

.section-2 {
  z-index: 5;
  position: relative;
  padding: 4em 0;
  background-color: #58b7cd;

  ul {
    columns: 2;
  }

  .icon {
    z-index: 1;
    @include size(6em);
    @include absolute($bottom: -6em, $right: 38em);
  }

  &::after {
    height: 8em;
    content: '';
    @include absolute($bottom: -5em, $left: -2em, $right: -2em);
    background: img('bg-section-2-down.png') right no-repeat;
    background-size: contain;
  }

  @include media-breakpoint(md) {
    padding: 12em 0 6em 0;

    &::after {
      left: 0;
      right: 10vw;
      bottom: -8em;
    }
  }
}

.section-3 {
  z-index: 4;
  padding: 8em 0 0;
  background-color: #f1f1f1;

  @include media-breakpoint(md, max) {
    text-align: center;
  }

  .pessoa-fisica {
    padding: 2em 0 8em;
    background-color: #e4eaec;
  }

  .pessoa-juridica {
    padding-bottom: 2em;
  }

  h2,
  h3 {
    color: #376e82;
  }

  h3 + img {
    max-width: 16em;
  }

  .logo {
    max-width: 11em;
  }

  .ans {
    max-width: 7em;
  }

  ul {
    color: #5c5c5c;
  }

  li {
    margin-top: 2em;
  }
}

.section-4 {
  z-index: 3;
  position: relative;
  background-color: #00c4ed;

  img {
    margin-top: -4em;
  }

  &::before {
    z-index: -1;
    content: '';
    height: 6em;
    @include absolute($top: -3.5em, $left: 3em, $right: 3em);
    background: img('bg-section-4-up.png') center no-repeat;
    background-size: contain;

    @include media-breakpoint(md) {
      @include size(40vw, 4em);
      transform: translateX(-50%);
      @include absolute($top: -3em, $left: 50%);
    }
  }
}

.section-5 {
  z-index: 2;
  padding: 4em 0;
  background-color: #c8ecea;

  h2 + img {
    height: 2em;
    margin: 0 0 -0.5em 0.5em;
  }
}

.section-6 {
  z-index: 1;
  position: relative;
  background-color: #6c9eac;

  &::before {
    height: 4em;
    content: '';
    @include absolute($top: -3em, $left: -2em, $right: -2em);
    background: img('bg-section-6-up.png') center no-repeat;
    background-size: 100% 100%;

    @include media-breakpoint(md) {
      @include size(40vw, 4em);
      transform: translateX(-50%);
      @include absolute($top: -3em, $left: 50%);
    }
  }

  .btn {
    margin-bottom: -3em;
  }
}
