/*
=======================================================
Buttons
=======================================================
1. Default Styles
=======================================================
*/

/*
-------------------------------------------------------
1. Default Styles
-------------------------------------------------------
*/

.btn {
  font-weight: 700;
  letter-spacing: 0.15rem;
  font-size: font-size(title-6);
  color: color(light);
  padding: 0.3em 2em;
  text-align: center;
  line-height: 1.5em;
  border-width: 2px;
  border-style: solid;
  text-transform: uppercase;
  border-radius: 3rem;
  transition: background 0.3s, border 0.3s, color 0.3s;
  @include btnColor(#b8ad8d);
  @include vcenter;

  &:focus,
  &:hover {
    outline: none;
    text-decoration: none;
    @include btnColor(darken(#b8ad8d, 10%));
  }

  span {
    @include vcenter;
  }

  &-icon {
    fill: color(dark);
    @include vcenter;
    @include size(1em);
    transition: fill 0.3s;

    span + &,
    & + span {
      margin-left: 0.7em;
    }
  }

  &--outline:not(:hover):not(:focus) {
    color: inherit;
    background-color: transparent;

    .btn-icon {
      fill: color(dark);
    }
  }

  &:disabled,
  &.is-disabled {
    z-index: -1;
    opacity: 0.6;
    position: relative;
    pointer-events: none;
  }
}
