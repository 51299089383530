/*
=======================================================
Typography
=======================================================
1. Font-Face
2. Defaults
=======================================================
*/

/*
-------------------------------------------------------
1. Font-Face
-------------------------------------------------------
*/

@include font-face('Neo Sans Pro', 'NeoSansPro', 'NeoSansPro-Regular', normal);
@include font-face('Neo Sans Pro', 'NeoSansPro', 'NeoSansPro-Bold', bold);

/*
-------------------------------------------------------
2. Defaults
-------------------------------------------------------
*/

body {
  color: color(dark);
  font-family: 'Neo Sans Pro', sans-serif;
  font-weight: normal;

  @include media-breakpoint(md, max) {
    font-size: 85%;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 700;
}
